import api from '@/utils/axios'
import i18n from '@/libs/i18n'

const state = () => ({
  permissionsDummy: [
    {
      id: '794f3fc4-bc28-41ad-8b95-1ba10ac70925',
      allAllowed: false,
      allDenied: false,
      name: 'Tercer Nivel',
      isCollapsed: true,
      nodes: [
        {
          id: '2b4ba5ed-63d8-4320-b54f-27e09ee6e10c',
          allAllowed: false,
          allDenied: false,
          name: 'Categoria',
          isCollapsed: true,
          items: [
            {
              name: 'Consultar Destinatario de Pago',
              status: true,
            },
            {
              name: 'Crear Destinatario de Pago',
              status: false,
            },
            {
              name: 'Crear Operacion',
              status: true,
            },
          ],
          nodes: [
            {
              id: '15b0ddff-f0cd-4b5a-81ba-a6d814b27ff7',
              allAllowed: false,
              allDenied: false,
              name: 'Sub nivel',
              isCollapsed: true,
              items: [
                {
                  name: 'Consultar Destinatario de Pago',
                  status: true,
                },
                {
                  name: 'Crear Destinatario de Pago',
                  status: false,
                },
                {
                  name: 'Crear Operacion',
                  status: true,
                },
              ],
              nodes: [
                {
                  id: '6eb5b61c-cebe-407b-83c6-42f60cede8d3',
                  allAllowed: false,
                  allDenied: false,
                  name: 'Sub nivel 2',
                  isCollapsed: true,
                  items: [
                    {
                      name: 'Consultar Destinatario de Pago',
                      status: true,
                    },
                    {
                      name: 'Crear Destinatario de Pago',
                      status: false,
                    },
                    {
                      name: 'Crear Operacion',
                      status: true,
                    },
                  ],
                },
              ],
            },
            {
              id: '26b87e57-f02d-4755-9d4d-7cd9e3158fa4',
              allAllowed: false,
              allDenied: false,
              name: 'Sub nivel',
              isCollapsed: true,
              items: [
                {
                  name: 'Consultar Destinatario de Pago',
                  status: true,
                },
                {
                  name: 'Crear Destinatario de Pago',
                  status: false,
                },
                {
                  name: 'Crear Operacion',
                  status: true,
                },
              ],
            },
          ],
        },
        {
          id: '9842274f-0191-47b5-873e-81a67cd1d485',
          allAllowed: false,
          allDenied: false,
          name: 'Pagos',
          isCollapsed: true,
          items: [
            {
              name: 'Consultar Destinatario de Pago',
              status: true,
            },
            {
              name: 'Crear Destinatario de Pago',
              status: false,
            },
            {
              name: 'Crear Operacion',
              status: true,
            },
          ],
        },
        {
          id: '1ecfd426-3608-44b6-b1b0-9b17a1d68b4e',
          allAllowed: false,
          allDenied: true,
          name: 'Acesso',
          isCollapsed: true,
          items: [
            {
              name: 'Consultar Destinatario de Pago',
              status: false,
            },
            {
              name: 'Crear Destinatario de Pago',
              status: false,
            },
            {
              name: 'Crear Operacion',
              status: false,
            },
          ],
        },
      ],
    },
    {
      id: '5afdf426-356f-4021-97b9-19e3b733b758',
      allAllowed: true,
      allDenied: false,
      name: 'Acesso 2',
      isCollapsed: true,
      items: [
        {
          name: 'Consultar Destinatario de Pago',
          status: true,
        },
        {
          name: 'Crear Destinatario de Pago',
          status: true,
        },
        {
          name: 'Crear Operacion',
          status: true,
        },
      ],
    },
  ],
  permissions: {

  },
})

const getters = {
  permissions(state) {
    return state.permissions
  },
}

const mutations = {
  SET_PERMISSIONS(state, payload) {
    state.permissions = payload
  },

  // Deprecated for now (collapse icon is changed inside the component data)
  CHANGE_COLLAPSE_ICON(state, { index }) {
    state.permissions[index].isCollapsed = !state.permissions[index]
      .isCollapsed
  },

  // This mutatios changes all item's status from a node's items and sub-nodes
  TOGGLE_ITEMS_STATUS(state, {
    nodes, items, bool, currentNode,
  }) {
    // if(bool && currentNode){
    //   currentNode.allAllowed = true
    //   currentNode.allDenied = false
    // }
    // else if(!bool && currentNode){
    //   currentNode.allAllowed = false
    //   currentNode.allDenied = true
    // }
    if (items) {
      items.forEach(item => {
        item.status = bool
      })
    }
    if (nodes) {
      nodes.forEach(node => {
        // if(bool){
        //   node.allAllowed = true
        //   node.allDenied = false
        // }
        // else{
        //   node.allAllowed = false
        //   node.allDenied = true
        // }
        this.commit('permissions/TOGGLE_ITEMS_STATUS', {
          nodes: node.nodes,
          items: node.items,
          bool,
          currentNode: node,
        })
      })
    }
  },

  // This mutation changes and individual item status
  CHANGE_ITEM_STATUS(state, { item, value }) {
    item.status = value
  },
}

const actions = {
  getPermissions(context, { roleId, applicationId }) {
    return new Promise((resolve, reject) => {
      api
        .get('/roles/permissions', {
          params: {
            RoleId: roleId,
            ApplicationId: applicationId,
          },
        })
        .then(response => {
          context.commit('SET_PERMISSIONS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  savePermissions(context, { permissions }) {
    return new Promise((resolve, reject) => {
      api
        .post('/roles/save_permissions', permissions)
        .then(response => {
          this._vm.$toast.success(i18n.t('successMessages.permissionsSaved'))
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  removePermissions(context) {
    context.commit('SET_PERMISSIONS', [])
    return true
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
