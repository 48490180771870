import axiosIdentity from '@utils/axiosIdentity'

function obtenerFechaActual() {
  const fecha = new Date()
  const año = fecha.getUTCFullYear().toString().padStart(4, '0')
  const mes = (fecha.getUTCMonth() + 1).toString().padStart(2, '0')
  const dia = fecha.getUTCDate().toString().padStart(2, '0')
  const hora = fecha.getUTCHours().toString().padStart(2, '0')
  const minutos = fecha.getUTCMinutes().toString().padStart(2, '0')
  const segundos = fecha.getUTCSeconds().toString().padStart(2, '0')
  const offset = fecha.getTimezoneOffset()
  const offsetSign = offset > 0 ? '-' : '+'
  const offsetHours = Math.abs(Math.floor(offset / 60)).toString().padStart(2, '0')
  const offsetMinutes = (offset % 60).toString().padStart(2, '0')
  const fechaFormateada = `${año}-${mes}-${dia}T${hora}:${minutos}:${segundos}${offsetSign}${offsetHours}:${offsetMinutes}`
  return fechaFormateada
}

export default {
  namespaced: true,
  state: {
    scope: {
      id: '',
      name: 'Nuevo scope',
      resources: [],
      dateCreated: obtenerFechaActual(),
      createdBy: '',
      dateUpdated: obtenerFechaActual(),
      updatedBy: '',
    },
  },
  getters: {
    getCurrency(state) {
      return state.scope
    },
  },
  mutations: {
  },
  actions: {
    getScopes() {
      return new Promise((resolve, reject) => {
        axiosIdentity
          .get('/scopes')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    newScopes(_, { data }) {
      return new Promise((resolve, reject) => {
        axiosIdentity
          .post('/scopes', data, { headers: { 'Content-Type': 'application/json' } })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateScope(_, { id, resources }) {
      return new Promise((resolve, reject) => {
        axiosIdentity
          .put(`/scopes/${id}`, { resources }, { headers: { 'Content-Type': 'application/json' } })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteScope(_, { id }) {
      return new Promise((resolve, reject) => {
        axiosIdentity
          .delete(`/scopes/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getScopeById(_, { id }) {
      return new Promise((resolve, reject) => {
        axiosIdentity
          .get(`/scopes/${id}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
