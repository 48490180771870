import api from '@/utils/axios'

const state = () => ({
  securityParams: [
    {
      property: 'CharactersNotAllowed',
      value: '?',
      edit: false,
    },
    {
      property: 'LDAPServer',
      value: 'aa738664-5be2-4cc5-ae04-bcb37ff2ca7e',
      edit: false,
    },
    {
      property: 'MaxiumNumberOfFailedAttempts',
      value: '3',
      edit: false,
    },
    {
      property: 'MaxLengthOfPassword',
      value: '8',
      edit: false,
    },
  ],
})

const getters = {
  securityParams(state) {
    return state.securityParams
  },
}

const mutations = {
  SET_SECURITY_PARAMS(state, payload) {
    // We assing edit property to every item
    payload.forEach(setting => {
      setting.edit = false
    })
    state.securityParams = payload
  },

  ADD_SECURITY_FORM_TO_TABLE(state) {
    if (!state.securityParams[0]?.toBeCreated || state.securityParams.length === 0) {
      state.securityParams.unshift({
        property: '',
        value: '',
        edit: false,
        toBeCreated: true,
      })
    }
  },
  CONFIRM_ADD_SECURITY_FORM_TO_TABLE(state) {
    state.securityParams[0].toBeCreated = false
  },
  CANCEL_ADD_SECURITY_FORM_TO_TABLE(state) {
    if (state.securityParams[0].toBeCreated) {
      state.securityParams.shift()
    }
  },
  EDIT_NEW_SECURITY(state, { field, value }) {
    if (state.securityParams[0].toBeCreated) {
      state.securityParams[0][field] = value
    }
  },

  TOGGLE_EDIT_SECURITY(state, { index }) {
    state.securityParams[index].edit = !state.securityParams[index].edit
  },

  CONFIRM_EDIT_SECURITY(state, { index, param }) {
    state.securityParams[index] = param
    state.securityParams[index].edit = false
  },
}

const actions = {
  getApplicationSettings(context, { applicationId }) {
    return new Promise((resolve, reject) => {
      api
        .get('/application_settings', {
          params: {
            ApplicationId: applicationId,
          },
        })
        .then(response => {
          context.commit('SET_SECURITY_PARAMS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  createApplicationSettings(context, appSetting) {
    return new Promise((resolve, reject) => {
      api
        .post('/application_settings', {
          applicationID: appSetting.applicationId,
          property: appSetting.property,
          value: appSetting.value,
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
