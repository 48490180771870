import api from '@/utils/axios'
import i18n from '@/libs/i18n'

const state = () => ({
  apps: [],
  selectedApp: JSON.parse(localStorage.getItem('selectedApp')),
  files: [],
  file: null,
})

const getters = {
  apps(state) {
    return state.apps
  },
  selectedApp(state) {
    return state.selectedApp || state.apps[0]
  },

  files(state) {
    return state.files
  },
  file(state) {
    return state.file
  },
}

const mutations = {
  // Deprecated Only one file allowed
  ADD_FILE(state, file) {
    state.files.push(file)
  },
  SET_FILE(state, file) {
    state.files = []
    state.files.push(file)
  },
  REMOVE_FILE(state, index) {
    state.files.splice(index, 1)
  },
  SET_APPS(state, payload) {
    state.apps = payload
  },
  SET_SELECTED_APP(state, app) {
    state.selectedApp = app
    localStorage.setItem('selectedApp', JSON.stringify(app))
  },
}

const actions = {
  getApps(context, payload) {
    return new Promise((resolve, reject) => {
      api
        .get('/applications')
        .then(response => {
          context.commit('SET_APPS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  registerApp(context, app) {
    return new Promise((resolve, reject) => {
      api
        .post('/applications/register', app, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          this._vm.$toast.success(i18n.t('successMessages.appRegistered'))
          resolve(response)
        })
        .catch(error => {
          this._vm.$toast.error(i18n.t('errorMessages.appNotRegistered'))
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
