const state = () => ({
  currentUser: JSON.parse(localStorage.getItem('userData')) || {},
  // currentUser: {name:"Usuario Prueba",email:"admin@monoestereo.com.mx",password:"12345678",role:"admin"},
})

const getters = {
  currentUser: state => state.currentUser,
}

const mutations = {
  SET_CURRENT_USER: (state, payload) => {
    state.currentUser = payload
  },
  CLEAR_CURRENT_USER: state => {
    state.currentUser = {}
  },
}

const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
