export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/usuarios',
    name: 'users',
    component: () => import('@/views/users/Users.vue'),
  },
  {
    path: '/crear-usuario',
    name: 'createUser',
    component: () => import('@/views/users/CreateUser.vue'),
  },
  {
    path: '/usuario/:id',
    // name: 'userProfile',
    component: () => import('@/views/users/User.vue'),
    meta: {
      pageTitle: 'Usuarios',
      fromIcon: 'UsersIcon',
      fromRoute: 'users',
      breadcrumb: [
        {
          text: 'Editar',
          active: true,
        },
      ],
    },
    children: [
      {
        path: 'general',
        name: 'userGeneralInfo',
        component: () => import('@/components/users/UserGeneralInfo.vue'),
      },
      {
        path: 'cambiar-contrasena',
        name: 'userChangePassword',
        component: () => import('@/components/users/UserChangePassword.vue'),
      },
      {
        path: 'roles',
        name: 'userRolesSettings',
        component: () => import('@/components/users/UserRolesSettings.vue'),
      },
      {
        path: 'permisos',
        name: 'userPermissionsSettings',
        component: () => import('@/components/users/UserPermissionsSettings.vue'),
      },
    ],
  },
  {
    path: '/roles',
    name: 'roles',
    props: true,
    component: () => import('@/views/roles/Roles.vue'),
  },
  {
    path: '/aplicaciones',
    name: 'apps',
    component: () => import('@/views/apps/Apps.vue'),
  },
  {
    path: '/seguridad-informatica',
    name: 'informaticSecurity',
    component: () => import('@/views/params/InformaticSecurity.vue'),
  },
  {
    path: '/configuracion-de-aplicacion',
    name: 'appSettings',
    component: () => import('@/views/params/AppsSettings.vue'),
  },
  {
    path: '/administracion-de-aplicacion',
    name: 'appAdmin',
    component: () => import('@/views/apps/AppAdmin.vue'),
  },
  {
    path: '/sessions',
    name: 'sessions',
    component: () => import('@/views/sessions/Sessions.vue'),
  },
  {
    path: '/sessionsv2',
    name: 'sessionsv2',
    component: () => import('@/views/sessions/SessionsV2.vue'),
  },
  {
    path: '/scopes/:id',
    name: 'ScopesForm',
    component: () => import('@/views/scopes/scopeForm.vue'),
  },
  {
    path: '/scopes',
    name: 'ScopesAdmin',
    component: () => import('@/views/scopes/Scopes.vue'),
  },
  {
    path: '/applications',
    name: 'Applications',
    component: () => import('@/views/applications/Applications.vue'),
  },
  {
    path: '/applications/:id',
    name: 'Applications',
    component: () => import('@/views/applications/Application.vue'),
  },
  {
    path: '/webhook/events',
    name: 'Events',
    component: () => import('@/views/webhooks/Events.vue'),
  },
  {
    path: '/webhooks',
    name: 'WebHook',
    component: () => import('@/views/webhooks/Webhooks.vue'),
  },
  {
    path: '/webhooks/:id',
    name: 'WebHook',
    component: () => import('@/views/webhooks/Webhook.vue'),
  },
]
