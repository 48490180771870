import api from '@/utils/axios'
import i18n from '@/libs/i18n'

const state = () => ({
  appsSettings: [
    {
      property: 'CharactersNotAllowed',
      value: '?',
      edit: false,
    },
    {
      property: 'LDAPServer',
      value: 'aa738664-5be2-4cc5-ae04-bcb37ff2ca7e',
      edit: false,
    },
    {
      property: 'MaxiumNumberOfFailedAttempts',
      value: '3',
      edit: false,
    },
    {
      property: 'MaxLengthOfPassword',
      value: '8',
      edit: false,
    },
  ],
})

const getters = {
  appsSettings(state) {
    return state.appsSettings
  },

}

const mutations = {
  SET_APPS_SETTINGS(state, payload) {
    // We assing edit property to every item
    payload.forEach(setting => {
      setting.edit = false
    })
    state.appsSettings = payload
  },

  ADD_SETTING_FORM_TO_TABLE(state) {
    if (!state.appsSettings[0]?.toBeCreated || state.appsSettings.length === 0) {
      state.appsSettings.unshift(
        {
          property: '',
          value: '',
          edit: false,
          toBeCreated: true,
        },
      )
    }
  },
  CONFIRM_ADD_SETTING_FORM_TO_TABLE(state) {
    state.appsSettings[0].toBeCreated = false
  },
  CANCEL_ADD_SETTING_FORM_TO_TABLE(state) {
    if (state.appsSettings[0].toBeCreated) {
      state.appsSettings.shift()
    }
  },
  EDIT_NEW_SETTING(state, { field, value }) {
    if (state.appsSettings[0].toBeCreated) {
      state.appsSettings[0][field] = value
    }
  },

  TOGGLE_EDIT_SETTING(state, { index }) {
    state.appsSettings[index].edit = !state.appsSettings[index].edit
  },

  CONFIRM_EDIT_SETTING(state, { index, param }) {
    state.appsSettings[index] = param
    state.appsSettings[index].edit = false
  },

}

const actions = {
  getEnviromentSettings(context, { applicationId }) {
    return new Promise((resolve, reject) => {
      api
        .get('/environment_settings', {
          params: {
            ApplicationId: applicationId,
          },
        })
        .then(response => {
          context.commit('SET_APPS_SETTINGS', response.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  createEnviromentSettings(context, enviromentSetting) {
    return new Promise((resolve, reject) => {
      api
        .post('/environment_settings', {
          applicationID: enviromentSetting.applicationId,
          property: enviromentSetting.property,
          value: enviromentSetting.value,
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
