// Axios
/* eslint-disable no-param-reassign */
import axios from 'axios'
import { isUserLoggedIn } from '@/auth/utils'
import authService from '@/utils/authorize-service'

import baseInterceptor from './baseInterceptor'

const AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_CONSOLE_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

AxiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const isSingIn = isUserLoggedIn()
    if (error.response.status === 401) {
      if (isSingIn) {
        await authService.signOut('/login')
      }
    }
  },
)

baseInterceptor(AxiosInstance)

export default AxiosInstance
/* eslint-enable no-param-reassign */
