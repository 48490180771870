import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = [
  {
    id: 'usdmxn',
    title: 'USDMXN',
    currency: 'USD',
    buy: 12.345678,
    sell: 12.345678,
    options: {
      ammounts: [
        '100',
        '500',
        '1000',
        '5000',
      ],
    },
    x: 0,
    y: 0,
    w: 4,
    h: 11,
    i: 0,
    static: false,
  },
  {
    id: 'usdeur',
    title: 'USDEUR',
    currency: 'USD',
    buy: 12.025866,
    sell: 12.021026,
    options: {
      ammounts: [
        '100',
        '500',
        '1000',
        '5000',
      ],
    },
    x: 4,
    y: 0,
    w: 4,
    h: 11,
    i: 1,
    static: false,
  },
  {
    id: 'mxnhun',
    title: 'MXNHUN',
    currency: 'MXN',
    buy: 12.025866,
    sell: 13.021026,
    options: {
      ammounts: [
        '100',
        '500',
        '1000',
        '5000',
      ],
    },
    x: 8,
    y: 0,
    w: 4,
    h: 11,
    i: 2,
    static: false,
  },
  {
    id: 'eurmxn',
    title: 'EURMXN',
    currency: 'EUR',
    buy: 12.025866,
    sell: 12.021026,
    options: {
      ammounts: [
        '100',
        '500',
        '1000',
        '5000',
      ],
    },
    x: 0,
    y: 10,
    w: 4,
    h: 11,
    i: 3,
    static: false,
  },
]
/* eslint-disable global-require */
mock.onGet('/widgets').reply(() => [200, data])
mock.onGet('/widgets/usdmxn').reply(() => [200, data.filter(x => x.id === 'usdmxn')[0]])
mock.onGet('/widgets/usdeur').reply(() => [200, data.filter(x => x.id === 'usdeur')[0]])
mock.onGet('/widgets/mxnhun').reply(() => [200, data.filter(x => x.id === 'mxnhun')[0]])
mock.onGet('/widgets/eurmxn').reply(() => [200, data.filter(x => x.id === 'eurmxn')[0]])
