import axios from 'axios'
import baseInterceptor from './baseInterceptor'
import useJwt from '@/auth/jwt/useJwt'

const AxiosNotificator = axios.create({
  baseURL: process.env.VUE_APP_NOTIFICATOR_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

baseInterceptor(AxiosNotificator)

export default AxiosNotificator
/* eslint-enable no-param-reassign */
