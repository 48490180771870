import AxiosNotificator from "@/utils/axiosNotificator"

export default {
  namespaced: true,
  state: {
  },
  getters: {
    getWebhook(state) {
      return state
    },
  },
  mutations: {
  },
  actions: {
    getEvents() {
      return new Promise((resolve, reject) => {
        AxiosNotificator
          .get('/webhooks_events')
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  }
}
